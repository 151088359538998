import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  Typography,
  Chip,
  Grid,
  Menu,
  MenuItem,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Add from "@mui/icons-material/Add";

import { EntityApi } from "@unity/components";
import config from "../config";
import { checkArray } from "../utils/checkArray";

export default function PollCreate({ open, onClose, poll, setPoll }) {
  const [name, setName] = useState("");
  const [options, setOptions] = useState(["yes"]);
  const [error, setError] = useState("");
  const [newOption, setNewOption] = useState("");
  const [polls, setPolls] = useState([]);
  const [openPoll, setOpenPoll] = useState(null);

  useEffect(() => {
    const getPolls = async () => {
      const res = await EntityApi.getEntityByType(config.pollEntityTypeId);
      if (res && res.success) {
        setPolls(res.data.data);
      }
    };
    getPolls();
  }, []);

  useEffect(() => {
    if (options.some((el) => el === newOption)) {
      setError("Options must be unique");
    } else {
      setError("");
    }
  }, [newOption, options]);

  const handleClose = () => {
    setName("");
    setOptions([]);
    onClose();
  };

  const savePoll = async () => {
    if (!poll) {
      setPoll({ name, options });
    }
    handleClose();
  };

  const deleteOption = (option) => {
    let localOptions = options.filter((el) => el !== option);
    setOptions(localOptions);
  };

  const addOption = () => {
    let localOptions = [...options];
    localOptions.push(newOption);
    setOptions(localOptions);
    setNewOption("");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create Poll</DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="flex-start">
          <Typography
            onClick={(e) => setOpenPoll(e.currentTarget)}
            color="primary"
            sx={{
              fontSize: 14,
              cursor: "pointer",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            Choose Existing
          </Typography>
          {checkArray(polls) ? (
            <Menu
              anchorEl={openPoll}
              open={Boolean(openPoll)}
              onClose={() => setOpenPoll(null)}
            >
              {polls.map((el) => (
                <MenuItem
                  key={el._id}
                  onClick={() => {
                    setName(el.name);
                    setOptions(el.options);
                    setPoll(el);
                    setOpenPoll(null);
                  }}
                >
                  {el.name}
                </MenuItem>
              ))}
            </Menu>
          ) : null}
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Question"
            fullWidth
            size="small"
          />
          <Stack direction="row" spacing={2}>
            <TextField
              label="Add new option"
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              sx={{ width: 300 }}
              size="small"
              error={Boolean(error)}
              helperText={error}
            />
            <Button
              disabled={!newOption || options.some((el) => el === newOption)}
              onClick={addOption}
              variant="contained"
              endIcon={<Add />}
              size="small"
            >
              Add
            </Button>
          </Stack>
          <Grid container spacing={1}>
            {options.map((el) => (
              <Grid item key={el}>
                <Chip
                  label={el}
                  onDelete={
                    options.length === 1 ? null : () => deleteOption(el)
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" disabled={error} onClick={savePoll}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
