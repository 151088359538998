import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  IconButton,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import Menu from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import { Resizable } from "re-resizable";

import LeftPanel from "../components/leftPanel";
import MessageScreen from "../components/messageScreen";

export default function Home() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [open, setOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);

  return (
    <Paper elevation={0} sx={{ pt: 1 }}>
      <Grid container spacing={0.5}>
        <Grid item xs="auto">
          <Resizable
            defaultSize={{
              width: 320,
            }}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            {matches ? (
              <LeftPanel
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
              />
            ) : (
              <Box sx={{ display: { md: "none" } }}>
                <IconButton onClick={() => setOpen((prev) => !prev)}>
                  <Menu />
                </IconButton>
              </Box>
            )}
          </Resizable>
        </Grid>
        <Grid item xs>
          <MessageScreen currentChat={currentChat} />
        </Grid>
      </Grid>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDrawer-paper": { width: 240 },
          display: { xs: "block", md: "none", p: 1 },
        }}
      >
        <LeftPanel currentChat={currentChat} setCurrentChat={setCurrentChat} />
      </Drawer>
    </Paper>
  );
}
