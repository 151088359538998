import { Box, Typography } from "@mui/material";
import React from "react";

export default function TextChip({ icon, action, text }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {icon ? icon : null}
      <Typography variant="subtitle2" color="primary">
        {text}
      </Typography>
      {action ? action : null}
    </Box>
  );
}
