import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { getUserInitials } from "../utils/getUserInitials";
import { usePusher } from "../services/PusherContext";
import { NotificationsApi, useAuth } from "@unity/components";

export default function UserItem({
  checkOnline,
  user,
  getColor,
  currentChat,
  setCurrentChat,
}) {
  const color = useRef("");
  const { pusher } = usePusher();
  const { auth } = useAuth();
  const me = user?.uuid === auth.id;

  const [count, setCount] = useState(0);

  useEffect(() => {
    color.current = getColor();
  }, []);

  useEffect(() => {
    const getCount = async () => {
      const res = await NotificationsApi.getCount(
        "Message",
        `${auth.id}_${user.uuid}`,
        true
      );
      if (res && res.success) {
        setCount(res.data);
      } else {
        setCount(0);
      }
    };
    let channela;
    let channelb;
    const channelConnect = async () => {
      channela = await pusher.subscribe(`${auth.id}_${user.uuid}`);
      channelb = await pusher.subscribe(`${user.uuid}_${auth.id}`);
      channela.bind(`notification`, async () => {
        window.dispatchEvent(new CustomEvent(`messageReceived_${user.uuid}`));
      });
      channelb.bind(`notification`, async () => {
        window.dispatchEvent(new CustomEvent(`messageReceived_${user.uuid}`));
      });
    };
    channelConnect();
    getCount();
    window.addEventListener(`messageReceived_${user.uuid}`, getCount);
    return () => {
      if (channela) channela.unbind("notification");
      if (channelb) channelb.unbind("notification");
      pusher.unsubscribe(`${auth.id}_${user.uuid}`);
      pusher.unsubscribe(`${user.uuid}_${auth.id}`);
      window.removeEventListener(`messageReceived_${user.uuid}`, getCount);
    };
  }, []);

  return (
    <ListItemButton
      onClick={async () => {
        setCurrentChat(user);
        setCount(0);
      }}
      selected={currentChat?.id === user.id}
      sx={{ overflow: "hidden", pt: "6px", pb: "6px" }}
    >
      <ListItemIcon>
        <Badge
          color="success"
          variant="dot"
          overlap="circular"
          badgeContent=" "
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          invisible={!checkOnline(user.uuid)}
        >
          <Avatar
            sx={{
              width: 36,
              height: 36,
              fontSize: 12,
              fontWeight: 600,
              bgcolor: color.current,
              color: "#212121",
            }}
          >
            {getUserInitials(user.name)}
          </Avatar>
        </Badge>
      </ListItemIcon>
      <Badge
        sx={{
          ".MuiBadge-badge": {
            minWidth: 14,
            height: 14,
            fontSize: "0.7rem",
            mt: 0.5,
          },
        }}
        color="success"
        badgeContent={count}
        invisible={!count || currentChat?.id === user.id}
      >
        <ListItemText
          primary={`${user.name}${me ? " (You)" : ""}`}
          sx={{ pt: 0.5 }}
        />
      </Badge>
    </ListItemButton>
  );
}
