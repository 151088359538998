const pusher_client_id = "c5350c80e013ebed7175";
const pusher_region = "eu";
let portal_url;
let initApiBase;
let initIsStaging;
let splitUrl = window.location.hostname.split(".");
// This is bad and need a better way to determine the name of the portal!
let portal_name = splitUrl[0] === "unity" ? "portal" : "home";

// work out if we are in staging or production and if running as local dev
if (process.env.NODE_ENV === "production") {
  if (splitUrl[2] === "fe") {
    initIsStaging = true;
  } else {
    initIsStaging = false;
  }
  initApiBase = window.location.hostname;

  // then create the portal url
  splitUrl[1] = portal_name;
  portal_url = splitUrl.join(".");
} else {
  // this is for when running as local dev
  initApiBase = "http://localhost:8080";
  initIsStaging = true;
  portal_url = "https://unity.portal.fe.staging.mybe.software";
}

const apiBase = initApiBase;
const isStaging = initIsStaging;

const config = {
  // ports
  serverPort: 8080,
  staging: isStaging,
  // this app url
  appURL: apiBase,
  // portal url
  portalURL: portal_url,
  // pusher
  pusherClientId: pusher_client_id,
  pusherRegion: pusher_region,
};

export default config;
