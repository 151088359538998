import React, { useState, useEffect } from "react";

import Timeline from "./timeline";
import MessageCreate from "./messageCreate";
import { useWindowSize } from "../utils/useWindowSize";
import ChatToolbar from "./chatToolbar";
import { NotificationsApi, useAuth } from "@unity/components";
import config from "../config";
import { GroupInfo } from "./groups/groupInfo";
import PollScreen from "./pollScreen";
import FilesScreen from "./filesScreen";

export default function MessageScreen({ currentChat }) {
  const windowSize = useWindowSize();
  const { auth } = useAuth();

  const isGroup = currentChat?._type_id === config.groupEntityTypeId;

  const [itemHeight, setItemHeight] = useState(100);
  const [messages, setMessages] = useState([]);
  const [update, setUpdate] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [channelName, setChannelName] = useState("");
  const [chatId, setChatId] = useState(null);

  useEffect(() => {
    setItemHeight(windowSize.height - 80);
  }, [windowSize.height]);

  useEffect(() => {
    const getData = async () => {
      if (currentChat) {
        const channelName = isGroup
          ? currentChat?._id
          : `${auth.id}_${currentChat.uuid}`;

        setChannelName(channelName);
        setChatId(
          isGroup
            ? currentChat?._id
            : [
                `${auth.id}_${currentChat.uuid}`,
                `${currentChat.uuid}_${auth.id}`,
              ]
        );
        const res = await NotificationsApi.getTypeNotifications(
          channelName,
          isGroup ? "Group" : "Message"
        );
        if (res && res.success) {
          setMessages(res.data.reverse());
        }
      }
    };
    getData();
    window.addEventListener(
      `messageReceived_${isGroup ? currentChat?._id : currentChat?.uuid}`,
      getData
    );
    setTabValue(0);
    return () => {
      window.removeEventListener(
        `messageReceived_${isGroup ? currentChat?._id : currentChat?.uuid}`,
        getData
      );
    };
  }, [currentChat?._id, currentChat?.uuid, update]);

  return (
    <div
      style={{
        width: "100%",
        height: itemHeight,
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <div>
        <ChatToolbar
          update={update}
          currentChat={currentChat}
          isGroup={isGroup}
          setOpenInfo={setOpenInfo}
          tabValue={tabValue}
          setTabValue={setTabValue}
        />
      </div>
      {tabValue === 0 ? (
        <Timeline
          messages={messages}
          parentHeight={itemHeight}
          setUpdate={setUpdate}
          isGroup={isGroup}
          currentChat={currentChat}
        />
      ) : tabValue === 1 ? (
        <PollScreen chatId={chatId} />
      ) : (
        <FilesScreen chatId={chatId} />
      )}
      {tabValue === 0 ? (
        <div style={{ overflow: "hidden", height: "min-content" }}>
          <MessageCreate
            currentChat={currentChat}
            isGroup={isGroup}
            setUpdate={setUpdate}
          />
        </div>
      ) : null}
      {isGroup ? (
        <GroupInfo
          open={openInfo}
          onClose={() => setOpenInfo(false)}
          data={currentChat}
          update={update}
        />
      ) : null}
    </div>
  );
}
