import React, { useState } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import Delete from "@mui/icons-material/Delete";

import { NotificationsApi } from "@unity/components";

export default function MessageMenu({ message, setUpdate }) {
  const [open, setOpen] = useState(null);

  const handleClose = () => {
    setOpen(null);
  };

  const handleDelete = async () => {
    handleClose();
    const res = await NotificationsApi.deleteNotification(message.id);
    if (res?.success) {
      setUpdate((prev) => !prev);
    }
  };

  return (
    <>
      <IconButton
        onClick={(e) => setOpen(e.currentTarget)}
        sx={{ width: 10, height: 10 }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu open={Boolean(open)} anchorEl={open} onClose={handleClose}>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon sx={{ color: "red" }}>
            <Delete />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
