import { List, ListItem } from "@mui/material";
import React, { useEffect, useState } from "react";

import { checkArray } from "../utils/checkArray";
import { AttendanceApi, PersonnelApi, useAuth } from "@unity/components";
import { SkeletonList } from "../common/skeletonList";
import UserItem from "./userItem";
import { usePusher } from "../services/PusherContext";

const avatarColors = [
  "#f3e5f5",
  "#ffebee",
  "#e3f2fd",
  "#e0f2f1",
  "#f1f8e9",
  "#fff3e0",
];

export default function UserList({ searchTerm, currentChat, setCurrentChat }) {
  const { auth } = useAuth();
  const { pusher } = usePusher();

  const [loggedinUsers, setLoggedinUsers] = useState([]);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      const res = await PersonnelApi.getPersonnel();
      if (res && res.success) {
        setUsers(res.data);
        const user = res.data[0];
        if (user) setCurrentChat(user);
      } else {
        setUsers([]);
      }
    };
    getUsers();
  }, [auth.id, pusher, setCurrentChat]);

  useEffect(() => {
    //get recent users maybe, or just first 10
    const getData = async () => {
      if (auth.attendance_access) {
        const res = await AttendanceApi.getStatusIndex(true);
        if (res && res.success) {
          setLoggedinUsers(res.data);
        } else {
          setLoggedinUsers([]);
        }
      }
    };
    getData();
  }, [auth.attendance_access]);

  const checkOnline = (userId) => {
    if (checkArray(loggedinUsers)) {
      return loggedinUsers.some((user) => user.user_uuid === userId);
    } else {
      return false;
    }
  };

  const getColor = () => {
    let color = avatarColors[Math.floor(Math.random() * avatarColors.length)];
    return color;
  };

  return users === null ? (
    <SkeletonList />
  ) : checkArray(users) ? (
    <List dense>
      {users
        .filter(
          (el) => el.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        )
        .map((el) => (
          <UserItem
            key={el._id}
            checkOnline={checkOnline}
            getColor={getColor}
            user={el}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
          />
        ))}
    </List>
  ) : null;
}
