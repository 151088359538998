import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Typography,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import AttachFile from "@mui/icons-material/AttachFile";
import Ballot from "@mui/icons-material/Ballot";
import Send from "@mui/icons-material/Send";
import Add from "@mui/icons-material/Add";
import Close from "@material-ui/icons/Close";

import {
  PersonnelApi,
  NotificationsApi,
  EntityApi,
  useAuth,
} from "@unity/components";
import PollCreate from "./pollCreate";
import FileUploader from "../common/fileUploader";
import { checkArray } from "../utils/checkArray";
import config from "../config";
import TextChip from "../common/textChip";

const victor = {
  name: "Victor Aienobe",
  id: "53584804-d9dc-4803-813b-270e874fe54d",
};

const james = {
  name: "James Hudson",
  id: "e0dd2ed1-b5fd-458c-992d-6acadcf1847e",
};

const isTest = false;

export default function MessageCreate({ currentChat, isGroup, setUpdate }) {
  const { auth, checkPermission } = useAuth();
  const canCreateMessage = checkPermission(`create_message_user`);
  const canCreatePoll = checkPermission(`create_poll_user`);
  const canCreateDocument = checkPermission(`create_document_user`);

  const [value, setValue] = useState("");
  const [poll, setPoll] = useState(null);
  const [openPoll, setOpenPoll] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [users, setUsers] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [quote, setQuote] = useState("");
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [addRecip, setAddRecip] = useState(false);
  const [channelName, setChannelName] = useState("");

  //used for testing to produce example data
  const [testUser, setTestUser] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      const res = await PersonnelApi.getPersonnel();
      if (res && res.success) {
        setUsers(res.data);
      } else {
        setUsers([]);
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    //check if user chat, then get channelname
    const getChannelName = async () => {
      if (isGroup) {
        setChannelName(currentChat?._id);
      } else {
        setChannelName(`${auth.id}_${currentChat?.uuid}`);
      }
    };
    getChannelName();
    handleReset();
  }, [currentChat]);

  const handleAddRecipeient = (user) => {
    setRecipients((prev) => [...prev, user]);
    //replace '@<usersearchterm>' with <username>
    setValue((prev) =>
      prev
        .split(" ")
        .map((el) => (el.indexOf("@") !== -1 ? user?.name : el))
        .join(" ")
    );
    setAddRecip(false);
  };

  const handleReset = () => {
    setValue("");
    setPoll(null);
    setRecipients([]);
    setQuote("");
    setAttachmentIds([]);
  };

  const handleKeyChange = useCallback(
    (e) => {
      const { key, code, shiftKey } = e;

      if (code === "Enter") {
        e.defaultMuiPrevented = true;
        e.preventDefault();
        if (canCreateMessage) handleSendMessage();
      } else if (isGroup && shiftKey && code === "Quote") {
        setAddRecip(true);
      } else if (addRecip && code === "Delete") {
        setQuote("");
      } else if (addRecip && code === "Backspace") {
        //test that user hasnt been deleted
        if (!quote) {
          setAddRecip(false);
        } else {
          setQuote((prev) => prev.substring(0, prev.length - 1));
        }
      } else if (addRecip) {
        setQuote((prev) => `${prev}${key}`);
      } else {
        setAddRecip(false);
      }
    },
    [isGroup, quote, addRecip, value]
  );

  const handleSendMessage = async () => {
    try {
      const payload = {
        name: `New Message`,
        description: value,
        tenant_uuid: auth?.tenant_uuid,
        link: `${window.location.hostname}/messaging/index`,
        link_action: "Go to messages",
        reference_type: isGroup ? "Group" : "Message",
        reference_id: channelName,
        poster: testUser ? testUser.name : auth.name,
        channel: channelName,
        event: "notification",
        read_by: [auth.id],
        additional_content: JSON.stringify({
          documentIds: attachmentIds.map((el) => el.id),
          poster_id: testUser ? testUser.id : auth.id,
        }),
      };
      let apiPromises = [NotificationsApi.saveNotification(payload)];
      if (checkArray(recipients)) {
        recipients.forEach((el) =>
          apiPromises.push(
            EntityApi.postEntity({
              type_id: config.groupMemberEntityTypeId,
              entity: {
                groupId: currentChat._id,
                userId: el.uuid,
                name: el.name,
              },
            })
          )
        );
      }
      if (poll) {
        apiPromises.push(
          EntityApi.postEntity({
            type_id: config.pollEntityTypeId,
            entity: {
              name: poll.name,
              options: poll.options,
              chatId: isGroup ? currentChat?._id : channelName,
            },
          })
        );
      }

      if (checkArray(attachmentIds)) {
        const res = await EntityApi.getEntityByType(
          config.documentIdEntityTypeId,
          channelName
        );
        if (res && res.success && checkArray(res.data.data)) {
          const currentData = res.data.data[0];
          const currentIds = currentData.documentIds;
          apiPromises.push(
            EntityApi.putEntity(currentData._id, {
              type_id: config.documentIdEntityTypeId,
              entity: {
                documentIds: currentIds.concat(
                  attachmentIds.map((el) => el.id)
                ),
              },
            })
          );
        } else {
          apiPromises.push(
            EntityApi.postEntity({
              type_id: config.documentIdEntityTypeId,
              entity: {
                chatId: channelName,
                documentIds: attachmentIds.map((el) => el.id),
              },
            })
          );
        }
      }
      Promise.all(apiPromises).then((res) => {
        handleReset();
      });
    } catch (err) {
      console.log(err);
    }
    setUpdate((prev) => !prev);
  };

  const handleDeleteFile = (file) => {
    let localFiles = [...attachmentIds];
    setAttachmentIds(localFiles.filter((el) => el.name !== file));
  };

  return (
    <>
      <Stack
        spacing={1}
        sx={{ p: 2, pl: "100px" }}
        className="tour-messagingcreate"
      >
        {isTest && process.env.NODE_ENV === "development" ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={testUser?.name === "Victor Aienobe"}
                  onChange={(e) =>
                    setTestUser(e.target.checked ? victor : null)
                  }
                />
              }
              label="Victor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={testUser?.name === "James Hudson"}
                  onChange={(e) => {
                    setTestUser(e.target.checked ? james : null);
                  }}
                />
              }
              label="James"
            />
          </FormGroup>
        ) : null}
        {addRecip ? (
          <Stack
            spacing={1}
            direction="row"
            sx={{
              overflow: "auto",
              "::-webkit-scrollbar": {
                height: 8,
                width: 300,
                backgroundColor: "#e0e0e0",
              },
              "::-webkit-scrollbar-track": {
                borderRadius: 2,
                backgroundColor: "#e0e0e0",
              },
              "::-webkit-scrollbar-thumb": {
                borderRadius: 2,
                backgroundColor: "#bdbdbd",
              },
            }}
          >
            {users
              .filter((el) =>
                quote
                  ? el.name.toUpperCase().indexOf(quote.toUpperCase()) !== -1
                  : el
              )
              .map((el) => (
                <Chip
                  key={el.uuid}
                  onClick={() => handleAddRecipeient(el)}
                  label={el.name}
                  deleteIcon={<Add />}
                  onDelete={() => handleAddRecipeient(el)}
                  disabled={el.uuid === auth.id}
                />
              ))}
          </Stack>
        ) : null}
        <TextField
          label="Start typing..."
          multiline
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyChange}
          fullWidth
          maxRows={8}
        />
        <Box sx={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
          <Box>
            {canCreateDocument && (
              <Tooltip title="attach file">
                <IconButton
                  onClick={() => setOpenUpload(true)}
                  size="small"
                  className="tour-messagingattach"
                >
                  <AttachFile />
                </IconButton>
              </Tooltip>
            )}
            {canCreatePoll && (
              <Tooltip title="create poll">
                <IconButton
                  onClick={() => setOpenPoll(true)}
                  size="small"
                  className="tour-messagingpolladd"
                >
                  <Ballot />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            {canCreateMessage && (
              <IconButton
                onClick={handleSendMessage}
                size="small"
                className="tour-messagingsubmit"
              >
                <Send />
              </IconButton>
            )}
          </Box>
        </Box>
        {addRecip ? <CircularProgress size={20} /> : null}
        {checkArray(recipients) ? (
          <Typography
            variant="subtitle2"
            color="primary"
          >{`Recipients: ${recipients.map((el) => el?.name)}`}</Typography>
        ) : null}
        {poll ? (
          <TextChip
            text={`Poll: ${poll?.name}`}
            action={
              <IconButton
                onClick={() => setPoll(null)}
                sx={{ width: 10, height: 10 }}
              >
                <Close />
              </IconButton>
            }
          />
        ) : null}
        {checkArray(attachmentIds) ? (
          <Grid container spacing={1}>
            {attachmentIds.map((el) => (
              <Grid item>
                <TextChip
                  key={el.id}
                  text={el.name}
                  action={
                    <IconButton
                      onClick={() => handleDeleteFile(el.name)}
                      sx={{ width: 10, height: 10 }}
                    >
                      <Close />
                    </IconButton>
                  }
                />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </Stack>
      <PollCreate
        open={openPoll}
        onClose={() => setOpenPoll(false)}
        poll={poll}
        setPoll={setPoll}
      />
      <FileUploader
        open={openUpload}
        setOpen={setOpenUpload}
        onUpdate={(ids) => {
          setAttachmentIds(ids);
        }}
      />
    </>
  );
}
