import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonAdd from "@mui/icons-material/PersonAdd";

import { checkArray } from "../../utils/checkArray";
import UserAutocomplete from "../../common/userAutocomplete";
import { EntityApi, useAuth } from "@unity/components";
import { useApiLoader } from "../../services/apiLoaderContext";
import config from "../../config";
import { useTour } from "../../services/TourContext";

export default function GroupManageDialog({
  open,
  onClose,
  group,
  setUpdate,
  setSnackbar,
}) {
  const { setLoading } = useApiLoader();
  const { auth } = useAuth();
  const tourOpen = useTour();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [members, setMembers] = useState([]);
  const [editMembers, setEditMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const getMembers = async () => {
      if (group?._id) {
        const res = await EntityApi.getEntityByType(
          config.groupMemberEntityTypeId,
          group._id
        );
        if (res.success) {
          setMembers(res.data.data);
          setEditMembers(res.data.data);
        } else {
          setMembers([]);
          setEditMembers([]);
        }
      }
    };
    getMembers();
  }, [group, setUpdate]);

  useEffect(() => {
    if (group?.name) {
      setName(group.name);
    }
    if (group?.description) {
      setDescription(group.description);
    }
  }, [group]);

  const handleRes = (res) => {
    if (res && res.status === 200) {
      setSnackbar({ open: true, message: "success", success: true });
    } else {
      setSnackbar({
        open: true,
        message: "There was an error with your request",
        success: false,
      });
    }
    setLoading(false);
    handleClose();
    setUpdate((prev) => !prev);
  };

  const handleClose = () => {
    setName("");
    setDescription("");
    setMembers([]);
    setEditMembers([]);
    onClose();
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  const handleCreate = async () => {
    const formData = {
      type_id: config.groupEntityTypeId,
      entity: {
        name,
        description,
      },
    };
    const res = await EntityApi.postEntity(formData);
    if (res && res.success) {
      checkMemberChange(res.data._id);
    }
    handleRes(res);
  };

  const handleUpdate = async () => {
    const formData = {
      type_id: config.groupEntityTypeId,
      entity: {
        name,
        description,
      },
    };
    const res = await EntityApi.putEntity(group?._id, formData);
    if (res && res.success) {
      checkMemberChange(res.data._id);
    }
    handleRes(res);
  };

  const checkMemberChange = async (groupId) => {
    //look for new members
    const postPromises = editMembers.map((el) => {
      //if in edit but not members,do a post to groupmembers
      if (!members.some((item) => item._id === el._id)) {
        return EntityApi.postEntity({
          type_id: config.groupMemberEntityTypeId,
          entity: { groupId, uuid: el.uuid, name: el.name },
        });
      }
    });
    //look for deleted members
    const deletePromises = members.map((el) => {
      //if in members but not edit, do a delete on groupmembers
      if (!editMembers.some((item) => item._id === el._id)) {
        return EntityApi.deleteEntity(el._id, config.groupMemberEntityTypeId);
      }
    });
    if (checkArray(postPromises)) {
      Promise.all(postPromises);
    }
    if (checkArray(deletePromises)) {
      Promise.all(deletePromises);
    }
  };

  const onDelete = (id) => {
    setEditMembers((prev) => prev.filter((el) => el._id !== id));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{group ? "Update Group" : "Create Group"}</DialogTitle>
        <DialogContent className="tour-messaginggroupmanage">
          <Stack spacing={2}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="name"
              sx={{ mt: 1 }}
            />
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="description"
            />
            <Paper variant="outlined" sx={{ minHeight: 150, p: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">Group Members</Typography>
                    <IconButton
                      className="tour-messaginggroupuseradd"
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      <PersonAdd />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item>
                  <Chip
                    label={
                      group
                        ? `${group.created_by_name} - OWNER`
                        : `${auth.name} - OWNER`
                    }
                  />
                </Grid>
                {checkArray(editMembers)
                  ? editMembers.map((el) => (
                      <Grid item key={el._id}>
                        <Chip
                          label={el.name}
                          onDelete={() => onDelete(el._id)}
                        />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Paper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="tour-messaginggroupcancel">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={group ? handleUpdate : handleCreate}
            className="tour-messaginggroupsave"
          >
            {group ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { width: 300 } }}
      >
        <Stack spacing={1} sx={{ p: 1 }}>
          <UserAutocomplete
            multiple
            value={editMembers}
            setValue={setEditMembers}
          />
          <Button
            onClick={() => {
              setAnchorEl(null);
            }}
            variant="contained"
          >
            Update
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
