const staging = true;
const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  groupEntityTypeId: staging
    ? "6571f6097e4234a569055893"
    : "66ed524d2cda342f5c008142",
  groupMemberEntityTypeId: staging
    ? "6571f6047e4234a569055892"
    : "66ed522ee9d9b4302302e4b2",
  pollEntityTypeId: staging
    ? "6571f535e772c38ccd0c02e2"
    : "66ed51cee03f7385f101bfe2",
  pollResultEntityTypeId: staging
    ? "6571f5c95fc75308600f2f82"
    : "66ed51f8485b7f496f0be7b2",
  documentIdEntityTypeId: staging
    ? "659537138e65004cee044732"
    : "66ed5272aeed7fe33303b9e2",
  resourceName: "messaging",
  defaultRole: "manager",
};
