import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Pusher from "pusher-js";

import { AuthProvider } from "@unity/components";
import Loading from "./common/loading";
import { ApiLoaderContext } from "./services/apiLoaderContext";
import { PusherContext } from "./services/PusherContext";
import { TourContext } from "./services/TourContext";
import Home from "./pages/index";
import config from "./config";
import pusherConfig from "./pusherConfig";

export default function Root(props) {
  let context = { ...props };

  const pusher = new Pusher(pusherConfig.pusherClientId, {
    cluster: pusherConfig.pusherRegion,
  });

  const [loading, setLoading] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("tour", function (e) {
      if (e.detail?.open) {
        setTourOpen(e.detail.open);
      } else {
        setTourOpen((prev) => !prev);
      }
    });
  }, []);

  return (
    <AuthProvider module_alias={config.resourceName} {...props}>
      <ApiLoaderContext.Provider
        value={{
          setLoading,
        }}
      >
        <PusherContext.Provider value={{ pusher }}>
          <TourContext.Provider value={tourOpen}>
            <Router>
              <Route
                render={({ location }) => (
                  <TransitionGroup className="transition-group">
                    <CSSTransition
                      key={location.key}
                      timeout={300}
                      classNames="fade"
                    >
                      <section className="route-section">
                        <Switch location={location}>
                          <Route path="/location" exact component={Location} />
                          {props.auth.access.intranet["A"] &&
                            props.auth.access.intranet["R"] && (
                              <Route
                                path="/messaging/index"
                                component={(route) => (
                                  <Home route={route} context={context} />
                                )}
                              />
                            )}
                        </Switch>
                        <Loading open={loading} />
                      </section>
                    </CSSTransition>
                  </TransitionGroup>
                )}
              />
            </Router>
          </TourContext.Provider>
        </PusherContext.Provider>
      </ApiLoaderContext.Provider>
    </AuthProvider>
  );
}
