import {
  Stack,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Box,
  Typography,
  LinearProgress,
  Radio,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { EntityApi, useAuth } from "@unity/components";
import { checkArray } from "../utils/checkArray";
import config from "../config";

const PollItem = ({ poll, onDelete }) => {
  const { auth } = useAuth();

  const [results, setResults] = useState(null);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const getPollResults = async () => {
      const res = await EntityApi.getEntityByType(
        config.pollResultEntityTypeId,
        poll._id
      );
      if (res && res.success) {
        setResults(res.data.data);
      }
    };
    getPollResults();
  }, [poll._id, update]);

  const handleUpdateValue = async (e) => {
    setValue(e.target.value);
    const existingResult = checkArray(results)
      ? results.find((el) => el.created_by === auth.id)
      : null;
    const res = existingResult
      ? await EntityApi.putEntity(existingResult._id, {
          type_id: config.pollResultEntityTypeId,
          entity: { option: e.target.value },
        })
      : await EntityApi.postEntity({
          type_id: config.pollResultEntityTypeId,
          entity: { pollId: `${poll._id}`, option: e.target.value },
        });
    setUpdate((prev) => !prev);
  };

  const getPercentage = (option) => {
    if (checkArray(results)) {
      const currentCount = results.filter((el) => el.option === option);
      return (currentCount.length / results.length) * 100;
    } else {
      return 0;
    }
  };

  return (
    <Paper variant="outlined" sx={{ bgcolor: "white", p: 1 }}>
      <Stack spacing={1}>
        <FormControl size="small">
          <FormLabel id="poll-label">{poll.name}</FormLabel>
          <RadioGroup value={value} onChange={handleUpdateValue}>
            {poll.options.map((el, i) => (
              <FormControlLabel
                key={i}
                value={el}
                control={<Radio size="small" />}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 1,
                    }}
                  >
                    <Typography>{el}</Typography>
                    {checkArray(results) ? (
                      <>
                        <LinearProgress
                          sx={{ width: 80 }}
                          variant="determinate"
                          value={getPercentage(el)}
                        />
                        <Typography>{`${getPercentage(el)}%`}</Typography>
                      </>
                    ) : null}
                  </Box>
                }
                sx={{ fontSize: 12 }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          size="small"
          color="error"
          disabled={poll.created_by !== auth.id}
        >
          Delete
        </Button>
      </Stack>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Delete?</DialogTitle>
        <DialogActions>
          <Button>Cancel</Button>
          <Button
            onClick={() => {
              onDelete(
                poll._id,
                results.map((el) => el._id)
              );
              setOpen(false);
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default function PollScreen({ chatId }) {
  const [polls, setPolls] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (Array.isArray(chatId)) {
        const apiArr = [];
        apiArr.push(
          EntityApi.getEntityByType(config.pollEntityTypeId, chatId[0])
        );
        apiArr.push(
          EntityApi.getEntityByType(config.pollEntityTypeId, chatId[1])
        );
        Promise.all(apiArr).then((res) => {
          if (res.every((el) => el?.success)) {
            //res=[{data:{data:[],}}]
            const test = res.map((el) => el.data.data);
            setPolls(test.flat());
          } else {
            setPolls([]);
          }
        });
      } else {
        const res = await EntityApi.getEntityByType(
          config.pollEntityTypeId,
          chatId
        );

        if (res && res.success) {
          setPolls(res.data.data);
        } else {
          setPolls([]);
        }
      }
    };
    getData();
  }, [chatId, update]);

  const deletePoll = async (id, resultIds) => {
    const res = await EntityApi.deleteEntity(id, config.pollEntityTypeId);
    if (checkArray(resultIds)) {
      resultIds.forEach(async (el) => {
        const res1 = await EntityApi.deleteEntity(
          el,
          config.pollResultEntityTypeId
        );
        if (res1 && res1.success) {
          setUpdate((prev) => !prev);
        }
      });
    }
  };

  return (
    <Stack spacing={1} sx={{ ml: 1, mr: 1 }}>
      {checkArray(polls) ? (
        polls.map((el) => (
          <PollItem key={el._id} poll={el} onDelete={deletePoll} />
        ))
      ) : (
        <Typography>There are no polls to see</Typography>
      )}
    </Stack>
  );
}
