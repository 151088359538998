import {
  Typography,
  Box,
  Stack,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Download from "@mui/icons-material/FileDownloadOutlined";

import { checkArray } from "../utils/checkArray";
import { NotificationsApi, DocumentVaultAPI, useAuth } from "@unity/components";
import TextChip from "../common/textChip";
import MessageMenu from "./messageMenu";

const MessageItem = ({
  data,
  auth,
  index,
  lastIndex,
  setUpdate,
  isGroup,
  currentChat,
}) => {
  const ref = useRef();
  const {
    created_at,
    id,
    description,
    additional_content,
    read_by,
    poster,
    deleted_at,
  } = data;
  const attached = JSON.parse(additional_content);
  const isOwner = attached?.poster_id === auth.id;

  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    const markAsRead = async () => await NotificationsApi.markAsRead(id, false);

    const getDocuments = async () => {
      let localDocs = [];
      const resPromises = attached?.documentIds.map((el) =>
        DocumentVaultAPI.getDocument(el)
      );
      Promise.all(resPromises).then((res) => {
        res &&
          res.map((el) => {
            if (el && el.success) {
              localDocs.push(el.data);
            }
          });
        setDocuments(localDocs);
      });
    };

    if (ref?.current && index === lastIndex) {
      ref.current.scrollIntoView();
    }
    if (checkArray(attached?.documentIds)) {
      getDocuments();
    }
    if (read_by.includes(auth.id) === false) {
      markAsRead();
    }
  }, [data]);

  const generateLink = async (doc_id) => {
    const res = await DocumentVaultAPI.generateTempLink(doc_id);
    window.open(res.data, "_blank");
  };

  const getMessageTime = (date) => {
    //if current date just show time, otherwise show prettyfied date
    const messageDate = new Date(date);
    const date1 = new Date(date);
    date1.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const hours = messageDate.getHours();
    const minutes = messageDate.getMinutes();
    const time = `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
    if (date1.getTime() === currentDate.getTime()) {
      return time;
    } else if (currentDate.getTime() - date1.getTime() === 86400000) {
      return `Yesterday ${time}`;
    } else {
      return date;
    }
  };

  const restoreMessage = async () => {
    const res = await NotificationsApi.restoreNotification(id);
    if (res?.success) {
      setUpdate((prev) => !prev);
    }
  };

  const getMessageLabel = () => {
    return `${!isOwner ? poster : ""} ${getMessageTime(created_at)}`;
  };

  const deletedMessage = (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Typography variant="body2" sx={{ fontStyle: "italic" }}>
        This message has been deleted
      </Typography>
      <Button
        onClick={restoreMessage}
        size="small"
        variant="text"
        sx={{ textDecoration: "underline" }}
      >
        Undo
      </Button>
    </Box>
  );

  return (
    <Grid ref={ref} container spacing={1}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          justifyContent={isOwner ? "flex-end" : "flex-start"}
        >
          <Grid
            item
            sx={{
              maxWidth: "80%",
              minWidth: 134,
            }}
          >
            <Typography color="textSecondary" variant="caption">
              {getMessageLabel()}
            </Typography>
            {deleted_at ? (
              deletedMessage
            ) : (
              <Box
                sx={{
                  width: "100%",
                  bgcolor: isOwner ? "#e8eaf6" : "#f5f5f5",
                  borderRadius: 1,
                  p: 1,
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Typography sx={{ flex: "auto" }} variant="body2">
                    {description}
                  </Typography>
                  {(isGroup && currentChat.created_by === auth.id) ||
                  isOwner ? (
                    <MessageMenu message={data} setUpdate={setUpdate} />
                  ) : null}
                </Box>
                {checkArray(documents)
                  ? documents.map((el) => (
                      <TextChip
                        text={el.name}
                        key={el.id}
                        action={
                          <IconButton
                            onClick={() => generateLink(el.id)}
                            sx={{ width: 10, height: 10 }}
                          >
                            <Download />
                          </IconButton>
                        }
                      />
                    ))
                  : null}
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function Timeline({
  messages,
  parentHeight,
  setUpdate,
  isGroup,
  currentChat,
}) {
  const lastIndex =
    messages && checkArray(messages) && messages[messages.length - 1]
      ? messages[messages.length - 1].id
      : null;
  const { auth } = useAuth();

  const [itemHeight, setItemHeight] = useState(100);

  useEffect(() => {
    setItemHeight(parentHeight - 190);
  }, [parentHeight]);

  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
        p: 3,
        height: itemHeight,
        overflow: "auto",
      }}
      className="tour-messagetimeline"
    >
      {checkArray(messages) ? (
        <Stack spacing={1}>
          {messages.map((el) => (
            <MessageItem
              key={el.id}
              data={el}
              auth={auth}
              index={el.id}
              lastIndex={lastIndex}
              setUpdate={setUpdate}
              isGroup={isGroup}
              currentChat={currentChat}
            />
          ))}
        </Stack>
      ) : (
        <Typography align="center">There are no messages to display</Typography>
      )}
    </Box>
  );
}
