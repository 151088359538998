import React, { useState, useEffect } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Add from "@mui/icons-material/Add";

import { checkArray } from "../../utils/checkArray";
import { EntityApi, useAuth } from "@unity/components";
import config from "../../config";
import GroupManageDialog from "./groupManageDialog";
import GroupMenu from "./menu";
import { SkeletonList } from "../../common/skeletonList";
import GroupItem from "./groupItem";
import SnackNotification from "../../common/snackNotification";
import { useTour } from "../../services/TourContext";

export default function GroupList({ searchTerm, currentChat, setCurrentChat }) {
  const { auth, checkPermission } = useAuth();
  const tourOpen = useTour();
  const canCreateGroup = checkPermission(`create_group_user`);

  const [groups, setGroups] = useState(null);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [update, setUpdate] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    message: "",
    success: "",
  });

  useEffect(() => {
    const getData = async () => {
      let apiPromises = [
        EntityApi.getEntityByType(config.groupEntityTypeId, auth.id),
      ];
      const res = await EntityApi.getEntityByType(
        config.groupMemberEntityTypeId,
        auth.id
      );
      if (res && res.success) {
        const promiseArr = res.data.data.map((el) =>
          EntityApi.getEntity(el.groupId, config.groupEntityTypeId)
        );
        apiPromises.push(promiseArr);
      }
      Promise.all(apiPromises.flat()).then((res) => {
        const localGroups = res.map((el) =>
          el.success
            ? Array.isArray(el.data?.data)
              ? el.data.data
              : el.data
            : []
        );
        const flatArr = localGroups.flat();
        let flatGroups = [
          ...new Map(flatArr.map((item) => [item["name"], item])).values(),
        ];
        setGroups(flatGroups);
      });
    };
    getData();
  }, [searchTerm, update, auth.id]);

  return (
    <>
      <List dense>
        {canCreateGroup && (
          <ListItemButton
            onClick={() => {
              setCurrentChat(null);
              setOpen(true);
              if (tourOpen) {
                window.dispatchEvent(
                  new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
                );
              }
            }}
            className="tour-messaginggroupadd"
          >
            <ListItemIcon sx={{ color: (theme) => theme.palette.primary.main }}>
              <Add />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: "primary.main" }}
              primary="New Group"
            />
          </ListItemButton>
        )}
        {groups === null ? (
          <SkeletonList />
        ) : checkArray(groups) ? (
          groups
            .filter(
              (el) =>
                el.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            )
            .map((el) => (
              <GroupItem
                key={el._id}
                group={el}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                setCurrentGroup={setCurrentGroup}
                setMenuOpen={setMenuOpen}
              />
            ))
        ) : null}
      </List>
      <GroupManageDialog
        open={open}
        onClose={() => {
          setOpen(false);
          setMenuOpen(null);
        }}
        group={currentGroup}
        setUpdate={setUpdate}
        setSnackbar={setMessage}
      />
      <GroupMenu
        open={menuOpen}
        onClose={() => {
          setMenuOpen(null);
        }}
        onManage={() => {
          setOpen(true);
          setMenuOpen(false);
        }}
        group={currentGroup}
        setUpdate={setUpdate}
        setSnackbar={setMessage}
      />
      <SnackNotification
        open={message.open}
        message={message.message}
        success={message.success}
        onClose={() =>
          setMessage({
            open: false,
            message: "",
            success: "",
          })
        }
      />
    </>
  );
}
