import React, { forwardRef } from "react";
import { Alert as MuiAlert, Snackbar } from "@mui/material";

const snackClose = () => {
  return;
};

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackNotification({ open, message, success, onClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose ? onClose : snackClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {success ? (
        <Alert
          onClose={onClose ? onClose : snackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      ) : (
        <Alert
          onClose={onClose ? onClose : snackClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      )}
    </Snackbar>
  );
}
