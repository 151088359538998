import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Download from "@mui/icons-material/Download";
import Delete from "@mui/icons-material/Delete";

import config from "../config";
import { EntityApi, DocumentVaultAPI, useAuth } from "@unity/components";

export default function FilesScreen({ chatId }) {
  const { checkPermission, auth } = useAuth();
  const canDownloadDocument = checkPermission(`download_document_user`);

  const [documents, setDocuments] = useState([]);
  const [chatDoc, setChatDoc] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const apiArr = [];
        if (Array.isArray(chatId)) {
          apiArr.push(
            EntityApi.getEntityByType(config.documentIdEntityTypeId, chatId[0])
          );
          apiArr.push(
            EntityApi.getEntityByType(config.documentIdEntityTypeId, chatId[1])
          );
        } else {
          apiArr.push(
            EntityApi.getEntityByType(config.documentIdEntityTypeId, chatId)
          );
        }
        Promise.all(apiArr).then((res) => {
          if (res.every((el) => el?.success)) {
            setChatDoc(res.map((el) => el.data.data[0]));
            let test = res.map((el) => el.data.data[0]?.documentIds);

            let docs = test.filter((el) => el).flat();
            let localDocs = [];
            const resPromises = docs.map((el) =>
              DocumentVaultAPI.getDocument(el)
            );
            Promise.all(resPromises).then((res) => {
              res &&
                res.map((el) => {
                  if (el && el.success) {
                    localDocs.push(el.data);
                  }
                });
              setDocuments(localDocs);
            });
          }
        });
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [chatId, update]);

  const generateLink = async (doc_id) => {
    const res = await DocumentVaultAPI.generateTempLink(doc_id);
    window.open(res.data, "_blank");
  };

  const handleDelete = async (id) => {
    // TODO: remove document ids from notification doc, no need for attachmentIds array in additional content
    const doc = chatDoc
      ? chatDoc.find((el) => el.documentIds.includes(id))
      : null;
    if (doc.documentIds.length > 1) {
      const res = await EntityApi.putEntity(doc._id, {
        type_id: config.documentIdEntityTypeId,
        entity: {
          documentIds: doc.documentIds.filter((el) => el !== id),
        },
      });
    } else {
      const res = await EntityApi.deleteEntity(
        doc._id,
        config.documentIdEntityTypeId
      );
    }
    setUpdate((prev) => !prev);
  };

  return (
    <TableContainer
      sx={{ maxWidth: window.innerWidth - 370, overflow: "auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Added By</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Created</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((el) => (
            <TableRow key={el.id}>
              <TableCell>{el.name}</TableCell>
              <TableCell>{el.created_by_name}</TableCell>
              <TableCell>{el.raw_type}</TableCell>
              <TableCell>{el.size}</TableCell>
              <TableCell>{el.created_at}</TableCell>
              <TableCell sx={{ position: "sticky", right: 0 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  {canDownloadDocument && (
                    <IconButton
                      color="primary"
                      onClick={() => generateLink(el.id)}
                    >
                      <Download />
                    </IconButton>
                  )}
                  {el?.user_ref === auth.id && (
                    <IconButton
                      color="error"
                      onClick={() => handleDelete(el.id)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
