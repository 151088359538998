import {
  Box,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import Info from "@mui/icons-material/Info";

export default function ChatToolbar({
  currentChat,
  isGroup,
  setOpenInfo,
  tabValue,
  setTabValue,
}) {
  return currentChat ? (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent={isGroup ? "space-between" : "flex-start"}
      sx={{ bgcolor: "#fafafa", m: 0, width: "100%", p: 1 }}
    >
      <Grid item>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Tooltip variant="h5" title={currentChat?.name || ""}>
            <Typography noWrap sx={{ maxWidth: 300 }}>
              {currentChat?.name}
            </Typography>
          </Tooltip>
          <Tabs
            value={tabValue}
            onChange={(e, newValue) => setTabValue(newValue)}
            className="tour-messagingtabs"
          >
            <Tab
              value={0}
              label="chat"
              sx={{
                textTransform: "none",
              }}
            />
            <Tab value={1} label="polls" sx={{ textTransform: "none" }} />
            <Tab value={2} label="files" sx={{ textTransform: "none" }} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item>
        <Tooltip title="Show info">
          <IconButton
            size="small"
            onClick={() => setOpenInfo(true)}
            sx={{ visibility: isGroup ? "visible" : "hidden" }}
          >
            <Info />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  ) : null;
}
