export const getUserInitials = (name) => {
  try {
    const nameArr = name.split(" ");
    const initialArr = nameArr.map((el) => {
      const x = el.split("");
      return x[0];
    });
    return initialArr.join("").toUpperCase();
  } catch (err) {
    return "";
  }
};
