import { Divider, Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import UserList from "./userList";
import GroupList from "./groups/groupList";
import SearchBox from "./searchBox";
import { useWindowSize } from "../utils/useWindowSize";

export default function LeftPanel({ currentChat, setCurrentChat }) {
  const windowSize = useWindowSize();

  const [searchTerm, setSearchTerm] = useState("");
  const [itemHeight, setItemHeight] = useState(100);

  useEffect(() => {
    const availableHeight = windowSize.height - 75;
    setItemHeight(availableHeight);
  }, [windowSize.height]);

  return (
    <Box
      sx={{
        bgcolor: "#fafafa",
        height: itemHeight,
        p: 1,
        borderRight: 1,
        borderColor: "#bdbdbd",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <SearchBox value={searchTerm} setValue={setSearchTerm} />
      <Divider />
      <Box
        className="tour-messaginguserlist"
        sx={{ height: itemHeight / 2, overflowY: "auto" }}
      >
        <UserList
          searchTerm={searchTerm}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
        />
      </Box>
      <Divider />
      <Box
        className="tour-messaginggrouplist"
        sx={{ height: itemHeight / 2, overflowY: "auto" }}
      >
        <GroupList
          searchTerm={searchTerm}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
        />
      </Box>
    </Box>
  );
}
