import { Skeleton, Stack } from "@mui/material";
import React from "react";

export const SkeletonList = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular" width="100%" height="40px" />
      <Skeleton variant="rectangular" width="100%" height="40px" />
      <Skeleton variant="rectangular" width="100%" height="40px" />
    </Stack>
  );
};
