import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";

import { checkArray } from "../../utils/checkArray";
import config from "../../config";
import { EntityApi } from "@unity/components";

export const GroupInfo = ({ open, onClose, data, update }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const getMembers = async () => {
      const res = await EntityApi.getEntityByType(
        config.groupMemberEntityTypeId,
        data?._id
      );
      if (res && res.success) {
        setMembers(res.data.data);
      } else {
        setMembers([]);
      }
    };
    getMembers();
  }, [update, data?._id]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemText primary="Name" secondary={data.name} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Description"
              secondary={
                <Fragment>
                  <Typography variant="body2">{data.description}</Typography>
                </Fragment>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Owner" secondary={data.created_by_name} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Members"
              secondary={
                <Fragment>
                  <Typography variant="body2">
                    {checkArray(members)
                      ? members.map((el, i) =>
                          i < members.length - 1 ? `${el.name}, ` : el.name
                        )
                      : ""}
                  </Typography>
                </Fragment>
              }
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
