import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React from "react";
import Close from "@mui/icons-material/Close";

export default function SearchBox({ value, setValue }) {
  return (
    <FormControl fullWidth className="tour-messagingsearchbar">
      <InputLabel htmlFor="search-label">Search users or groups...</InputLabel>
      <OutlinedInput
        id="search-label"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setValue("")} edge="end">
              <Close />
            </IconButton>
          </InputAdornment>
        }
        label="Search users or groups..."
      />
    </FormControl>
  );
}
